@import 'variables';
@import "~bootstrap/scss/mixins";

& {
  --bs-primary: #{$primary};
  --bs-link-color: #{$primary};
}

.form-control {
  @include box-shadow($input-box-shadow);

  &:focus {
    border-color: $input-focus-border-color;
    box-shadow: $input-focus-box-shadow;
  }
}

.form-select {
  @include box-shadow($form-select-box-shadow);

  &:focus {
    border-color: $form-select-focus-border-color;
    box-shadow: $form-select-focus-box-shadow;
  }
}

.btn-primary {
  @include button-variant($primary, $primary);
}

@import "~bootstrap/scss/forms/form-check";
@import "~bootstrap/scss/dropdown";