@font-face {
  font-family: 'FS Emeric';
  src: url('fonts/fsemeric-regular-webfont.woff2') format('woff2'),
    url('fonts/fsemeric-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'FS Emeric';
  src: url('fonts/fsemeric-italic-webfont.woff2') format('woff2'),
    url('fonts/fsemeric-italic-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'FS Emeric';
  src: url('fonts/fsemeric-medium-webfont.woff2') format('woff2'),
    url('fonts/fsemeric-medium-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;

}

@font-face {
  font-family: 'FS Emeric';
  src: url('fonts/fsemeric-mediumitalic-webfont.woff2') format('woff2'),
    url('fonts/fsemeric-mediumitalic-webfont.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'FS Emeric';
  src: url('fonts/fsemeric-bolditalic-webfont.woff2') format('woff2'),
    url('fonts/fsemeric-bolditalic-webfont.woff') format('woff');
  font-weight: 800;
  font-style: italic;

}

@font-face {
  font-family: 'FS Emeric';
  src: url('fonts/fsemeric-bold-webfont.woff2') format('woff2'),
    url('fonts/fsemeric-bold-webfont.woff') format('woff');
  font-weight: 800;
  font-style: normal;

}

@font-face {
  font-family: 'FS Emeric';
  src: url('fonts/fsemeric-light-webfont.woff2') format('woff2'),
    url('fonts/fsemeric-light-webfont.woff') format('woff');
  font-weight: 200;
  font-style: normal;

}

@font-face {
  font-family: 'FS Emeric';
  src: url('fonts/fsemeric-lightitalic-webfont.woff2') format('woff2'),
    url('fonts/fsemeric-lightitalic-webfont.woff') format('woff');
  font-weight: 200;
  font-style: italic;

}