@use "sass:math";
@import "variables";
@import '~bootstrap/scss/mixins';

.btn.btn-default {

  @include button-variant($white,
    $gray-400,
    $hover-background: $gray-200,
    $hover-border: $gray-400,
    $active-background: $gray-300 );
  white-space: nowrap;
}

td .btn-group-vertical {
  width: 100%;
}