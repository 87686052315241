@import 'variables';

html {
  min-height: 100%;
  padding: env(safe-area-inset);
}

body {
  min-height: 100vh;
  min-height: 100svh;
  display: flex;
  flex-direction: column;

  #current-user-icon {
    --fa-primary-color: #{$indigo-200};
    --fa-secondary-color: var(--bs-primary);
    --fa-secondary-opacity: 1;
  }

  #main-nav {
    font-family: $headings-font-family;
  }

  main {
    padding-bottom: $spacer;
  }

  footer {
    margin-top: auto;
    border-top: 1px solid var(--bs-border-color, #{$border-color});
  }
}