@import "variables";
@import '~bootstrap/scss/mixins';

.card {
  &.card-primary {
    .card-header {
      margin: -1px;
      margin-bottom: 0;
      --bs-card-cap-color: #{$white};
      --bs-card-cap-bg: var(--bs-primary, #{$primary});
      --bs-card-border-color: var(--bs-primary, #{$primary});
    }

    .card-footer {
      --bs-card-cap-padding-y: #{$spacer};

      display: flex;
      flex-direction: column;
      gap: $spacer;

      @include media-breakpoint-up(md) {
        flex-direction: row;
      }

    }
  }
}
