@use 'sass:math';

$indigo: #00205c;
$cyan: #34c1d6;

$primary: $indigo !default;
$indigo-300: #8080a2;
$indigo-200: #b6b5c9;

$info: $cyan;
$info-bg-subtle: #ccf0f5;
$info-border-subtle: #ccf0f5;

$border-radius: 0;
$border-radius-sm: 0;
$border-radius-lg: 0;
$border-radius-xl: 0;
$border-radius-2xl: 0;

$tooltip-max-width: 250px;

$font-family-sans-serif: 'Open Sans', Arial, sans-serif;
$headings-font-family: 'FS Emeric', 'Open Sans', Arial, sans-serif;

$enable-reduced-motion: false;

$navbar-light-active-color: $primary;

$navbar-brand-height: 2.5rem;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$body-bg: $light;
$table-bg: $white;

$breadcrumb-bg: $white;
$breadcrumb-active-color: $text-muted;
$breadcrumb-padding-y: math.div($spacer, 2);
$breadcrumb-padding-x: $spacer;

$info-text: $body-color;

$tooltip-color: $body-color;
$tooltip-bg: $info-bg-subtle;
$tooltip-opacity: 1;

$navbar-nav-link-padding-x: $spacer;

$nav-tabs-border-width: 0;
$nav-tabs-link-active-color: $white;
$nav-tabs-link-active-bg: $primary;