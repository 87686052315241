body.hee {
  $primary: #a3195b;
  @import 'apply_branding';
}

body.vpc {
  $primary: #6886C3;
  $min-contrast-ratio: 3;
  @import 'apply_branding';
}

.navbar-brand {
  .brand-text {
    .yellow {
      color: #F9AF15;
    }

    .blue {
      color: #6886C3;
    }

    .purple {
      color: #a3195b;
    }

    .dark-blue {
      color: #231f4f;
    }
  }
}